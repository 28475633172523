import React, { useEffect, useRef, useState } from 'react'
import Button from '../../buttons/Button'
import { CenteredSection, SmallPageSection } from '../../layout/PageComponents'
import Form from '../../forms/Form'
import Facebook from '../../svg_icons/Facebook'
import styled from 'styled-components'
import useLocation from '../../../../hooks/useLocation'
import CopyToClipboardButton from '../../buttons/CopyToClipboardButton'
import Copy from '../../svg_icons/Copy'
import DynamicModal from '../DynamicModal'

// We can't change the look of the Google sign in button, so we make the Facebook button to look like the Google button
const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	> button {
		max-width: 390px;
		padding: 13px 0 12px;
		background: #202124;
		border-radius: 3px;
	}
`
const ButtonContentContainer = styled.div`
	position: relative;
	> svg {
		position: absolute;
		left: 8px;
		transform: translateY(-20px);
		z-index: 1;
		fill: #202124;
	}
	> div {
		position: absolute;
		left: 2px;
		bottom: -10px;
		width: 36px;
		height: 36px;
		background: white;
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
	}
	> span {
		padding-left: 35px;
		display: block;
		width: 100%;
	}
`
const GoogleContainer = styled.div`
	> div {
		display: flex;
		justify-content: center;
	}
`

const SocialMediaButtons = ({ authRequest, t, i18n }) => {
	const googleButtonRef = useRef(null)
	const googleContainerRef = useRef(null)
	const [googleErrorMessage, setGoogleErrorMessage] = useState()

	useEffect(() => {
		// Render Google button when the Google script has loaded
		const renderGoogleButton = () => {
			if (googleButtonRef.current && google?.accounts?.id) {
				google.accounts.id.initialize({
					client_id: window.ejblaGoogleClientId,
					use_fedcm_for_prompt: true,
					callback: response => {
						console.log(response)
						authRequest(
							{ jsw: response.credential },
							errorMessage => {
								setGoogleErrorMessage(errorMessage)
							},
							'google',
						)
					},
				})
				const containerWidth = googleContainerRef.current.offsetWidth
				google.accounts.id.renderButton(googleButtonRef.current, {
					theme: 'filled_black',
					text: 'continue_with',
					width: containerWidth < 390 ? containerWidth : 390, // The Facebook button is 390px wide, so try to align it with that
					logo_alignment: 'left',
					locale: i18n.language,
				})
			}
		}
		if (typeof google !== 'undefined') {
			renderGoogleButton()
		} else {
			// Google's SDK script hasn't loaded yet, so force load it
			window.googleAsyncDownload(renderGoogleButton)
		}
	}, [googleButtonRef.current])

	const facebookSubmitHandler = (inputValues, onSubmitFinished) => {
		const fbLogin = () =>
			FB.login(
				response => {
					if (response.authResponse) {
						authRequest(response, onSubmitFinished, 'facebook')
					} else if (response.status === 'unknown') {
						// TODO: 1 works, not 2.
						// 1.	User cancelled login, then we do nothing and return to login modal.
						// 2.	User needed to do something more in the FB login window like for instance
						// 		re-activating the ability to use this login-function ("activating apps, websites and games").
						// 		In that case we somehow need to get the response a second time and then call authRequest.
						onSubmitFinished()
					} else {
						onSubmitFinished(t('common:errormessages.generic'))
					}
				},
				{
					scope: 'email,public_profile',
				},
			)
		if (typeof FB !== 'undefined') {
			fbLogin()
		} else {
			// Facebook's SDK script hasn't loaded yet, so force load it
			window.fbAsyncDownload(fbLogin)
		}
	}
	const facebookText = t('copy:sessionModal.continueFacebook')
	return (
		<>
			<Form onSubmit={facebookSubmitHandler}>
				<ButtonContainer>
					<Button buttonType="RECTANGLE" wide text={facebookText} hasOwnStyling>
						<ButtonContentContainer>
							<span>{facebookText}</span>
							<Facebook />
							<div />
						</ButtonContentContainer>
					</Button>
				</ButtonContainer>
			</Form>
			<SmallPageSection ref={googleContainerRef}>
				<GoogleContainer ref={googleButtonRef} />
			</SmallPageSection>
			<DynamicModal displayModal={!!googleErrorMessage} setDisplayModal={setGoogleErrorMessage} zIndex={9002}>
				<CenteredSection>
					{googleErrorMessage}
					<br />
					<br />
					<br />
					<Button onClick={() => setGoogleErrorMessage(null)}>{t('common:buttons.tryAgain')}</Button>
				</CenteredSection>
			</DynamicModal>
		</>
	)
}

// Social media login is not supported well in webview. Therefore ask the user to open the page in their browser
export const SocialMediaCopyButton = ({ t }) => (
	<div style={{ width: '100%' }}>
		<p>{t('copy:sessionModal.facebookOrGoogle')}</p>
		<SmallPageSection>
			<CopyToClipboardButton textToCopy={useLocation().href} wide>
				<Button buttonType="RECTANGLE" color="OUTLINED_BLACK" wide>
					{t('copy:shareSection.copy')}
					&nbsp;&nbsp;
					<Copy />
				</Button>
			</CopyToClipboardButton>
		</SmallPageSection>
	</div>
)

export default SocialMediaButtons
