import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { theme } from '../../../utils/theme'
import Breakpoint from '../responsive/Breakpoint'
import Slider from 'react-slick'
import { DynamicPageSectionRow, PageSidePaddingWrapper, RepulsiveTitleSection } from '../layout/PageComponents'
import { isPhone, isTablet } from '../../../utils/screenSizeHelper'
import CrocodileRight from '../svg_icons/CrocodileRight'

const SliderWrapper = styled.div`
	overflow: hidden;
	display: grid;
	.slick-list {
		${theme.breakpoints.tablet.upToAndIncluding} {
			padding-left: ${theme.paddings.small};
		}
		${theme.breakpoints.tablet.overAndExcluding} {
			margin-right: -${theme.paddings.small};
		}
	}
`
const ButtonsWrapper = styled.div`
	display: flex;
	padding-right: ${theme.paddings.XSmall};
	${theme.breakpoints.tablet.overAndExcluding} {
		// Because of overflow:hidden on SliderWrapper that slices 3px off of slider
		margin-right: -${theme.paddings.smallInt - 3}px;
	}
	> svg:first-child {
		transform: rotateZ(180deg);
	}
`
const SlideWrapper = styled.div`
	outline: none;
	${({ isSlider }) =>
		isSlider &&
		css`
			padding: 0 ${theme.paddings.XSmall} 0 0;
			${theme.breakpoints.tablet.overAndExcluding} {
				padding: 0 ${theme.paddings.small} 0 0;
			}
		`}}
`
const SliderRepulsiveTitleSection = styled(RepulsiveTitleSection)`
	${({ noTitle }) =>
		noTitle &&
		`${theme.breakpoints.tablet.upToAndIncluding} {
		margin-bottom: 0;
	}`}
`

const ReusableSlider = ({ children, settings, title, desktopSlidesToShow = 4 }) => {
	if (children.length < 2) return null

	const sliderRef = useRef(null)
	const desktopSlides = children.length > desktopSlidesToShow ? desktopSlidesToShow : children.length
	const showDesktopArrows = children.length > desktopSlidesToShow
	settings = {
		dots: false,
		infinite: false,
		slidesToShow: 1.5,
		arrows: false,
		swipeToSlide: true,
		ref: sliderRef,
		...settings,
	}
	const [allowLeftSlide, setAllowLeftSlide] = useState(settings.infinite)
	const [allowRightSlide, setAllowRightSlide] = useState(true)
	const beforeChange = (currentIndex, nextIndex) => {
		if (settings.infinite) return
		if (nextIndex === 0) {
			// The slider is at the start
			setAllowLeftSlide(false)
		} else if (!allowLeftSlide) {
			// The slider was at the start, but is not longer at the start
			setAllowLeftSlide(true)
		}
		if (nextIndex + desktopSlidesToShow >= children.length) {
			// The slider is at the end
			setAllowRightSlide(false)
		} else if (!allowRightSlide) {
			// The slider was at the end, but is not longer at the end
			setAllowRightSlide(true)
		}
	}
	const renderCards = (children, isMobile, isSlider) => {
		const cards = children.map((child, key) => {
			return (
				<SlideWrapper key={key} isSlider={isSlider}>
					{child}
				</SlideWrapper>
			)
		})
		if (!settings.infinite) {
			if (isMobile) cards.push(<SlideWrapper key={children.length + 1} isSlider={isSlider} />)
		}
		return cards
	}
	useEffect(() => {
		// When the slider is infinite, it starts incorrectly when rendered on mobile/tablet because we have slidesToShow 1.5 (i.e. not a whole number) so jump 0.5 steps
		if (settings.infinite && (isPhone() || isTablet())) sliderRef.current.slickGoTo(0.5)
	}, [])

	return (
		<>
			{(showDesktopArrows || title) && (
				<PageSidePaddingWrapper>
					<SliderRepulsiveTitleSection noTitle={!title}>
						<h2>{title}</h2>
						<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
							{showDesktopArrows && (
								<ButtonsWrapper>
									<CrocodileRight
										width="30px"
										height="30px"
										style={{ opacity: allowLeftSlide ? 1 : 0.3 }}
										onClick={() => allowLeftSlide && sliderRef.current.slickPrev()}
									/>
									<CrocodileRight
										width="30px"
										height="30px"
										style={{ opacity: allowRightSlide ? 1 : 0.3 }}
										onClick={() => allowRightSlide && sliderRef.current.slickNext()}
									/>
								</ButtonsWrapper>
							)}
						</Breakpoint>
					</SliderRepulsiveTitleSection>
				</PageSidePaddingWrapper>
			)}
			<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>
				<SliderWrapper>
					<Slider {...settings}>{renderCards(children, true, true)}</Slider>
				</SliderWrapper>
			</Breakpoint>
			<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
				<PageSidePaddingWrapper>
					{showDesktopArrows ? (
						<SliderWrapper>
							<Slider
								{...settings}
								slidesToShow={desktopSlides}
								slidesToScroll={desktopSlides}
								swipe={false}
								swipeToSlide={false}
								beforeChange={beforeChange}
							>
								{renderCards(children, false, true)}
							</Slider>
						</SliderWrapper>
					) : (
						<DynamicPageSectionRow margins={{ inner: theme.margins.smallInt }}>
							{renderCards(children, false, showDesktopArrows)}
						</DynamicPageSectionRow>
					)}
				</PageSidePaddingWrapper>
			</Breakpoint>
		</>
	)
}

export default ReusableSlider
