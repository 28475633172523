import { ProfilePicture } from './CourseComponents'
import FollowButton from '../buttons/FollowButton'
import React from 'react'
import styled from 'styled-components'
import { theme } from '../../../utils/theme'
import { TextSpan } from '../Typography'
import Verified from '../svg_icons/Verified'
import ToolTip from './ToolTip'
import { LeftAlignedFlexBox } from '../layout/PageComponents'
import { isRootDomain } from '../../../utils/applicationHelper'
import ConditionalLink from './ConditionalLink'

export const StyledSmallHostSection = styled.div`
	display: flex;
	align-items: center;
	> div:first-child {
		margin-right: ${theme.margins.small};
	}
`
export const ButtonSection = styled.div`
	display: flex;
	flex-wrap: wrap;
	> * {
		margin-top: ${theme.margins.XSmall};
		:first-child {
			margin-right: ${theme.margins.XXSmall};
		}
	}
`
export const SmallHostSection = ({ host, t, isOpenedInContentSideBar, closeSideBar }) => {
	const {
		id,
		isPublished,
		fullName,
		subHostFullName,
		hostPicture,
		subHostPicture,
		hostSince,
		displayNameExtensionCoursePage,
		isVerified,
		url,
		path,
	} = host
	const courseHostFullName = subHostFullName || fullName
	const courseHostPicture = subHostPicture || hostPicture
	const displayHostPageLink = !isRootDomain() || (isPublished && host.isMarketplace) // Only display link to host page if we are on the host's page. On marketplace we only link there if they have enabled that link
	const to = displayHostPageLink && path
	const onClick = !isRootDomain() ? null : isOpenedInContentSideBar ? () => closeSideBar(true) : null
	return (
		<StyledSmallHostSection>
			<div>
				<ConditionalLink to={to} onClick={onClick}>
					<ProfilePicture profileName={courseHostFullName} width={150} imagePath={courseHostPicture} />
				</ConditionalLink>
			</div>
			<div>
				<LeftAlignedFlexBox>
					<p>
						<ConditionalLink to={to} onClick={onClick}>
							{courseHostFullName}
						</ConditionalLink>
					</p>
					{isVerified && (
						<ToolTip
							name="verifiedTooltip"
							place="top"
							icon={<Verified viewBox="0 0 92.44 87" width={19} height={18} />}
						>
							{t('copy:coursePage.verifiedTooltip')}
						</ToolTip>
					)}
				</LeftAlignedFlexBox>
				<TextSpan textColor="lightTextColor">
					{displayNameExtensionCoursePage || t('copy:coursePage.hostSince', { year: hostSince })}
				</TextSpan>
				<ButtonSection>
					{displayHostPageLink && <FollowButton favouritableType="hostPage" favouritableId={id} />}
				</ButtonSection>
			</div>
		</StyledSmallHostSection>
	)
}
