import React, { useState } from 'react'
import { getIdFromUrl } from '../../../utils/applicationHelper'

// Some pages that are fully rendered with client side routing rely on the ID in the URL to know which data to get with graphQL, e.g. SubCategoryPage and CollectionPage
// Therefore if the the URL changes while on these pages and they need to render after an update (e.g. the ContentSideBar is opened and the user favourites the course in the side bar)
// they will get the incorrect ID from the current URL (e.g the course's URL).
// This wrapper fixes that issue by remembering the URL's ID from when the component initially mounted and to only use that one

// It also has support for SSR rendered content, by receiving the memorizedPageModel (e.g. a sub category or a collection) prop.
// If the memorizedPageModel is for the current page (i.e. its ID is the same as the current URL's ID) it means it was server side rendered, so send it down.
// Otherwise it wasn't server side rendered, so nullify it to make sure the children gets the new current content with graphQL instead
const MemorizedIdWrapper = ({ children, ...props }) => {
	let id, memorizedPageModel
	if (props.type === 'MARKETPLACE') {
		id = props.slug
		memorizedPageModel = id !== props.memorizedPageModel?.slug ? null : props.memorizedPageModel
	} else {
		id = props.id || getIdFromUrl()
		memorizedPageModel = id !== props.memorizedPageModel?.id ? null : props.memorizedPageModel
	}
	const [memorizedId] = useState(id)
	return React.cloneElement(children, { ...props, id: memorizedId, memorizedPageModel })
}
export default MemorizedIdWrapper
