import React from 'react'
import {
	CenteredSection,
	DynamicPageSection,
	GrayBox,
	LazyLoad,
	LeftAlignedFlexBox,
	PageSidePaddingWrapper,
	XSmallPageSection,
} from '../../layout/PageComponents'
import { theme } from '../../../../utils/theme'
import { P1, P2 } from '../../Typography'
import ConditionalLink from '../ConditionalLink'
import Button from '../../buttons/Button'
import { HeaderWrapper } from '../../../courses/show/HeaderSection'
import { ContentSliderImage } from '../../../courses/show/ContentSlider'
import { replaceGoldTags } from './SaFrontendComponent'
import parseDangerousHTML from '../../../../utils/parseDangerousHTML'

const CenteredText = ({ frontendComponent, t }) => {
	const { description, headline, link, linkText, secondaryLink, secondaryLinkText, backgroundColor, image } =
		frontendComponent
	const content = (
		<CenteredSection>
			{parseDangerousHTML(replaceGoldTags(description), <P1 />)}
			{headline && (
				<>
					<XSmallPageSection />
					<P2 textColor="lightTextColor">{headline}</P2>
				</>
			)}
			{(link || secondaryLink) && (
				<DynamicPageSection margins={{ top: theme.margins.largeInt }}>
					<CenteredSection>
						<LeftAlignedFlexBox>
							{link && (
								<ConditionalLink to={link}>
									<Button>{linkText || t('common:buttons.readmore')}</Button>
								</ConditionalLink>
							)}
							{secondaryLink && (
								<ConditionalLink to={secondaryLink}>
									<Button color="OUTLINED_BLACK">{secondaryLinkText || t('common:buttons.readmore')}</Button>
								</ConditionalLink>
							)}
						</LeftAlignedFlexBox>
					</CenteredSection>
				</DynamicPageSection>
			)}
		</CenteredSection>
	)
	return (
		<DynamicPageSection margins={{ all: theme.margins.XXLargeInt }}>
			{image ? (
				<LazyLoad>
					<HeaderWrapper>
						<ContentSliderImage image={image} />
						<div>
							<div>
								<PageSidePaddingWrapper>{content}</PageSidePaddingWrapper>
							</div>
						</div>
					</HeaderWrapper>
				</LazyLoad>
			) : (
				<>
					{backgroundColor ? (
						<GrayBox style={{ padding: `${theme.paddings.XXLarge} 0` }} backgroundColor={backgroundColor}>
							<PageSidePaddingWrapper>{content}</PageSidePaddingWrapper>
						</GrayBox>
					) : (
						<PageSidePaddingWrapper>{content}</PageSidePaddingWrapper>
					)}
				</>
			)}
		</DynamicPageSection>
	)
}

export default CenteredText
