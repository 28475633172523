import React from 'react'
// Parses a String into an HTML objective. We need this for markdown content from contentful.
// ONLY use this for HTML which you now is safe (e.g. HTML generated from markdown), as it is dangerous to
// use for normal HTML (especially for user generated HTML) since it opens up the possibility for XSS (cross site scripting)
export default (HTMLString, DOMElement = <p />) => {
	let newDOMElement = { ...DOMElement }
	newDOMElement.props = { ...DOMElement.props }
	newDOMElement.props.dangerouslySetInnerHTML = {
		__html: HTMLString,
	}
	return newDOMElement
}
