import {
	CenteredSection,
	CenteredSmallPageSection,
	LeftAlignedFlexBox,
	PageSection,
	SmallPageSection,
} from '../../layout/PageComponents'
import { P2, UnderlinedA, UnderlinedSpan } from '../../Typography'
import Button from '../../buttons/Button'
import React from 'react'
import CrocodileUp from '../../svg_icons/CrocodileUp'
import CrocodileDown from '../../svg_icons/CrocodileDown'
import ToggleableElement from '../../other/ToggleableElement'
import SocialMediaButtons, { SocialMediaCopyButton } from './SocialMediaButtons'
import { isWebview } from '../../../../utils/applicationHelper'

const SignUpContent = ({ dispatch, t, onLogin, ...contextData }) => {
	const { message, title, tooltip, socialMediaButtonProps } = contextData
	return (
		<>
			<CenteredSection>
				{title || message || tooltip ? (
					<>
						<P2>{title || t('copy:sessionModal.signUpTitle')}</P2>
						<SmallPageSection>{message}</SmallPageSection>
						{tooltip && (
							<ToggleableElement
								toggler={toggled => (
									<CenteredSection>
										<LeftAlignedFlexBox>
											<UnderlinedSpan>{tooltip.question}</UnderlinedSpan>
											{toggled ? <CrocodileUp /> : <CrocodileDown />}
										</LeftAlignedFlexBox>
									</CenteredSection>
								)}
								skipPadding
							>
								{tooltip.answer}
							</ToggleableElement>
						)}
					</>
				) : (
					<>
						<P2>{t('copy:sessionModal.signUpTitle')}</P2>
						<SmallPageSection>{t('copy:sessionModal.signUpSubTitle')}</SmallPageSection>
					</>
				)}
				{!isWebview() && (
					<>
						<SocialMediaButtons {...socialMediaButtonProps} />
						<div>{t('copy:sessionModal.or')}</div>
					</>
				)}
				<SmallPageSection>
					<Button
						buttonType="RECTANGLE"
						wide
						onClick={() =>
							dispatch({
								type: 'SESSION_MODAL_DISPLAY_STATE',
								data: { displayState: 'SIGN_UP_WITH_EMAIL' },
							})
						}
					>
						{t('copy:sessionModal.createAccountEmail')}
					</Button>
				</SmallPageSection>
				{isWebview() && (
					<CenteredSmallPageSection>
						<PageSection>{t('copy:sessionModal.or')}</PageSection>
						<SocialMediaCopyButton t={t} />
					</CenteredSmallPageSection>
				)}
				<p>
					{t('copy:sessionModal.alreadyHaveAccount')}&nbsp;
					<UnderlinedA
						onClick={() =>
							dispatch({
								type: 'SESSION_MODAL_DISPLAY_STATE',
								data: { displayState: 'LOG_IN', callback: null, title: null, message: null, tier: null },
							})
						}
					>
						{t('copy:sessionModal.signIn')}
					</UnderlinedA>
				</p>
			</CenteredSection>
		</>
	)
}

export default SignUpContent
