import { Trans } from 'react-i18next-new'
import axios from 'axios'
import useRoutes from '../../../../hooks/useRoutes'
import { CenteredSmallPageSection, RepulsiveRow, SmallPageSection } from '../../layout/PageComponents'
import Button from '../../buttons/Button'
import Form from '../../forms/Form'
import TextInput from '../../forms/TextInput'
import CheckboxInput from '../../forms/CheckboxInput'
import { P2, UnderlinedA } from '../../Typography'
import React from 'react'
import ToggleableElement from '../../other/ToggleableElement'
import CrocodileUp from '../../svg_icons/CrocodileUp'
import CrocodileDown from '../../svg_icons/CrocodileDown'
import getWhiteLabel from '../../../../hooks/getWhiteLabel'
import TextAreaInput from '../../forms/TextAreaInput'

const ConfirmEmailContent = ({ dispatch, t, onLogin, ...contextData }) => {
	const {
		hostWantsToUpgradeTier,
		hostAccountWasCreated,
		emailToBeConfirmed,
		confirmEmailAuthenticityToken,
		initiateOnboarding,
		showPhoneInput,
		isHost,
		pwa,
	} = contextData
	const routes = useRoutes()
	const handleSubmit = (inputValues, onSubmitFinished) => {
		const { phone, email, newsletter, referral, signupPurpose } = inputValues
		axios({
			method: 'patch',
			url: routes.api.v1.auth.confirmEmail.url,
			data: {
				authenticity_token: confirmEmailAuthenticityToken,
				user: {
					phone,
					email,
					newsletter,
					used_signup_referral_code: referral,
					signup_purpose: signupPurpose,
				},
				isHost: isHost,
				initiate_onboarding: initiateOnboarding,
			},
		})
			.then(response => {
				if (response.data.success === true) {
					onLogin(response.data.user, hostWantsToUpgradeTier, hostAccountWasCreated, response.data.initiateOnboarding)
				} else {
					onSubmitFinished(t('common:errormessages.generic'))
				}
			})
			.catch(error => {
				if (error.response) {
					onSubmitFinished(error.response.data.errorMessage || t('common:errormessages.generic'))
				}
			})
	}
	return (
		<>
			<CenteredSmallPageSection>
				<P2>{t('copy:sessionModal.confirmEmailHeadline')}</P2>
				<SmallPageSection>{t('copy:sessionModal.confirmEmailBody')}</SmallPageSection>
			</CenteredSmallPageSection>
			<Form onSubmit={handleSubmit}>
				{(showPhoneInput || isHost) && <TextInput type="tel" name="phone" required />}
				{isHost && <TextAreaInput name="signupPurpose" required />}
				<TextInput type="email" name="email" value={emailToBeConfirmed} required />
				<CheckboxInput
					name="acceptPolicies"
					required
					label={
						<Trans i18nKey={'common:forms:acceptPolicies'} values={{ val: 'useragreement' }}>
							I accept the
							<UnderlinedA target="_blank" rel="noopener" href={routes.custom.termsOfUse.url}>
								terms of use
							</UnderlinedA>
						</Trans>
					}
				/>
				{!getWhiteLabel().active && !isHost && <CheckboxInput name="newsletter" />}
				{hostAccountWasCreated && (
					<ToggleableElement
						skipPadding
						toggler={toggled => (
							<RepulsiveRow>
								<p>{t('copy:sessionModal.referral')}</p>
								{toggled ? <CrocodileUp /> : <CrocodileDown />}
							</RepulsiveRow>
						)}
					>
						<TextInput name="referral" />
					</ToggleableElement>
				)}
				<SmallPageSection>
					<Button buttonType="RECTANGLE" wide>
						{t('copy:sessionModal.completeSignup')}
					</Button>
				</SmallPageSection>
			</Form>
		</>
	)
}

export default ConfirmEmailContent
