import React from 'react'
import SearchBar, { CloseIcon, SearchIcon } from '../../reusable_components/search/SearchBar'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { theme } from '../../../utils/theme'

const InputContainer = styled.div`
	height: 40px;
	background: white;
	color: black;
	transition:
		border-bottom-right-radius 0.3s,
		border-bottom-left-radius 0.3s;
	border-bottom-right-radius: 30px;
	border-bottom-left-radius: 30px;
	border-top-right-radius: 30px;
	border-top-left-radius: 30px;
	> input {
		height: 100%;
		padding-left: ${theme.paddings.large} !important;
		${theme.breakpoints.phone.range} {
			padding-right: ${theme.paddings.small};
		}
		${theme.breakpoints.phone.overAndExcluding} {
			padding-right: ${theme.paddings.large} !important;
		}
		text-align: center;
	}
	> div:first-child,
	> ${SearchIcon} {
		left: 20px !important;
		bottom: 6px !important;
	}
	> ${CloseIcon} {
		right: 10px !important;
		bottom: 0 !important;
	}
`

const ContainerWrapper = styled.div`
	margin-top: ${theme.margins.small};
	width: 100%;
	max-width: 400px;
	.react-autosuggest__container--open > div {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
`

const BigSearchBar = ({ t, searchResultsElement }) => {
	const history = useHistory()
	const wrapper = children => <InputContainer>{children}</InputContainer>
	return (
		<ContainerWrapper id="BIG_SEARCH_BAR">
			<SearchBar
				t={t}
				history={history}
				wrapper={wrapper}
				searchResultsElement={searchResultsElement}
				onInputFocus={() => {
					// If the user has scrolled down, remove the blackened overlay if they focus the input
					const fixedHeader = document.getElementById('FIXED_HEADER_PLACEHOLDER')
					const belowFixedHeader = document.getElementById('BELOW_FIXED_HEADER')
					if (fixedHeader) fixedHeader.style.opacity = '0'
					if (belowFixedHeader) belowFixedHeader.style.removeProperty('box-shadow')
				}}
				onInputBlur={() => {
					const fixedHeader = document.getElementById('FIXED_HEADER_PLACEHOLDER')
					const belowFixedHeader = document.getElementById('BELOW_FIXED_HEADER')
					if (fixedHeader) fixedHeader.style.removeProperty('opacity')
					if (belowFixedHeader) belowFixedHeader.style.boxShadow = 'rgb(0 0 0 / 20%) 0px -30px 25px' // If changing this, also change in FixedHeaderPlaceHolder's useEffect
				}}
			/>
		</ContainerWrapper>
	)
}

export default BigSearchBar
