import { css } from 'styled-components'
import { theme } from '../../utils/theme'

export const pageSidePadding = css`
	width: 100%;
	max-width: ${theme.widths.pageMaxWidthExcludingPadding};
	margin-left: auto;
	margin-right: auto;
	padding-top: 0;
	padding-bottom: 0;
	${theme.breakpoints.tablet.upToAndIncluding} {
		padding-left: ${theme.paddings.small};
		padding-right: ${theme.paddings.small};
	}
	${theme.breakpoints.laptop.range} {
		padding-left: ${theme.paddings.large};
		padding-right: ${theme.paddings.large};
	}
	${theme.breakpoints.laptop.overAndExcluding} {
		padding-left: ${theme.paddings.XXLarge};
		padding-right: ${theme.paddings.XXLarge};
	}
`

export const XLargeVerticalMargin = css`
	margin-bottom: ${theme.margins.XLarge};
	margin-top: ${theme.margins.XLarge};
`
export const XXLargeVerticalMargin = css`
	margin-bottom: ${theme.margins.XXLarge};
	margin-top: ${theme.margins.XXLarge};
`
export const largeVerticalMargin = css`
	margin-bottom: ${theme.margins.large};
	margin-top: ${theme.margins.large};
`
export const smallVerticalMargin = css`
	margin-bottom: ${theme.margins.small};
	margin-top: ${theme.margins.small};
`
export const xSmallVerticalMargin = css`
	margin-bottom: ${theme.margins.XSmall};
	margin-top: ${theme.margins.XSmall};
`
export const xxSmallVerticalMargin = css`
	margin-bottom: ${theme.margins.XXSmall};
	margin-top: ${theme.margins.XXSmall};
`
export const noMarginForFirstAndLastChild = css`
	> *:first-child {
		margin-top: 0;
	}
	> *:last-child {
		margin-bottom: 0;
	}
`
export const divider = ({ color = theme.colors.black }) => css`
	border-top: ${color} solid 1px;
	width: 100%;
	opacity: 50%;
`
export const repulsiveRow = css`
	display: flex;
	justify-content: space-between;
	align-items: ${({ alignItems = 'center' }) => alignItems};
	cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
	width: 100%;
	& > *:not(:first-child) {
		margin-left: ${theme.margins.small};
	}
`
export const text = ({ maxRows, textColor }) => css`
	${textColor && `color: ${theme.colors[textColor]};`};
	a {
		${textColor && `color: ${theme.colors[textColor]};`};
	}
	${maxRows && limitRows(maxRows)}
`

export const centeredContent = css`
	${({ onlyCenterOnMobile }) => css`
		${onlyCenterOnMobile ? `${theme.breakpoints.tablet.upToAndIncluding} {` : ''}
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		${onlyCenterOnMobile ? `}` : ''}
	`}
`
export const pageSectionRow = (
	margin,
	numberOfChildren,
	alignItems = 'stretch',
	centered = false,
	childrenPerRow,
	childrenPerRowDesktop,
	childrenPerRowMobile,
	maxWidth,
	breakpoint = 'tablet',
	reversedOnMobile, // Reverse the order of children on mobile
	reversedOnDesktop, // Reverse the order of children on desktop
	preserveWidth, // Preserve childrens' original width
) => {
	let innerHorizontalDesktopMargin =
		margin?.innerHorizontalDesktop ||
		margin?.innerHorizontal ||
		margin?.innerDesktop ||
		margin?.inner ||
		margin?.desktop ||
		margin?.all ||
		0
	let innerVerticalDesktopMargin =
		margin?.innerVerticalDesktop ||
		margin?.innerVertical ||
		margin?.innerDesktop ||
		margin?.inner ||
		margin?.desktop ||
		margin?.all ||
		0
	let innerVerticalMobileMargin =
		margin?.innerVerticalMobile ||
		margin?.innerMobile ||
		margin?.innerVertical ||
		margin?.inner ||
		margin?.mobile ||
		margin?.all ||
		0
	let innerHorizontalMobileMargin =
		margin?.innerHorizontalMobile ||
		margin?.innerMobile ||
		margin?.innerHorizontal ||
		margin?.inner ||
		margin?.mobile ||
		margin?.all ||
		0
	childrenPerRowDesktop = childrenPerRowDesktop || childrenPerRow || numberOfChildren
	childrenPerRowMobile = childrenPerRowMobile || childrenPerRow || 1
	return css`
		display: flex;
		flex-wrap: wrap;
		align-items: ${alignItems};
		${theme.breakpoints[breakpoint].overAndExcluding} {
			${centered && 'justify-content: center;'}
			${reversedOnDesktop ? 'flex-direction: row-reverse;' : ''}
			${pageSectionRowMargins(
				preserveWidth,
				childrenPerRowDesktop,
				innerHorizontalDesktopMargin,
				innerVerticalDesktopMargin,
				reversedOnDesktop,
			)}
		}
		${theme.breakpoints[breakpoint].upToAndIncluding} {
			${centered && 'justify-content: center;'}
			${preserveWidth && 'flex-direction: column;'}
			${reversedOnMobile ? 'flex-direction: column-reverse;' : ''}
			${pageSectionRowMargins(
				preserveWidth,
				childrenPerRowMobile,
				innerHorizontalMobileMargin,
				innerVerticalMobileMargin,
				reversedOnMobile,
			)}
		}
		${maxWidth &&
		`
  max-width: ${maxWidth};
  margin-left: auto;
  margin-right: auto;
  `}
	`
}
const pageSectionRowMargins = (
	preserveWidth,
	childrenPerRow,
	innerHorizontalMargin,
	innerVerticalMargin,
	reversed,
) => css`
	${preserveWidth
		? ''
		: css`
				> * {
					width: calc(
						${Math.floor((100 * 100) / childrenPerRow) / 100}% -
							${(innerHorizontalMargin * (childrenPerRow - 1)) / childrenPerRow}px
					);
				}
			`}
	> *:not(:nth-child(${childrenPerRow}n + 1)) {
		margin-left: ${reversed ? 0 : innerHorizontalMargin}px;
		margin-right: ${reversed ? innerHorizontalMargin : 0}px;
	}
	> *:nth-child(${childrenPerRow}n + 1) {
		margin-right: 0;
		margin-left: 0;
	}
	> *:nth-child(n + ${childrenPerRow + 1}) {
		margin-top: ${reversed ? 0 : innerVerticalMargin}px;
		margin-bottom: ${reversed ? innerVerticalMargin : 0}px;
	}
	> *:not(:nth-child(n + ${childrenPerRow + 1})) {
		margin-top: 0;
		margin-bottom: 0;
	}
`

// If don't want any margin, use 0.1px or just don't specify a margin instead of using 0px, since 0 will count as false in the logical operators
export const dynamicPageSection = (
	{
		all,
		mobile,
		desktop,
		top,
		bottom,
		topMobile,
		bottomMobile,
		topDesktop,
		bottomDesktop,
		outer,
		outerMobile,
		outerDesktop,
	},
	breakpoint = 'tablet',
) => css`
	margin-top: ${top || outer || all || 0}px;
	margin-bottom: ${bottom || outer || all || 0}px;
	width: 100%;
	> *:first-child {
		margin-top: 0;
	}
	> *:last-child {
		margin-bottom: 0;
	}
	${breakpoint &&
	css`
		${theme.breakpoints[breakpoint].upToAndIncluding} {
			margin-top: ${topMobile || mobile || top || outerMobile || outer || all || 0}px;
			margin-bottom: ${bottomMobile || mobile || bottom || outerMobile || outer || all || 0}px;
		}
		${theme.breakpoints[breakpoint].overAndExcluding} {
			margin-top: ${topDesktop || desktop || top || outerDesktop || outer || all || 0}px;
			margin-bottom: ${bottomDesktop || desktop || bottom || outerDesktop || outer || all || 0}px;
		}
	`}
`

export const textColors = css`
	color: ${theme.colors.textColor};
	:hover {
		color: ${theme.colors.textColor};
	}
`

export const callToAction = ({ centered, allowMultipleRows, flipSvg }) => css`
	user-select: none;
	color: ${theme.colors.linkColor};
	:hover {
		color: ${theme.colors.linkHoverColor};
	}
	cursor: pointer;
	line-height: 19px;
	display: inline-block;
	min-width: max-content;
	text-decoration: underline;
	& > * {
		display: inline;
	}
	svg {
		margin-left: 4px;
		transform: rotateZ(${flipSvg ? 180 : 0}deg);
		transition: transform 0.5s;
	}
	${centered &&
	`
  width: 100%;
  text-align: center;
  `};
	${allowMultipleRows &&
	`
  min-width: initial;
`};
`

export const limitRows = numberOfRows => css`
	overflow-wrap: break-word;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: ${numberOfRows};
`

export const hideScrollbar = css`
	::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
`
