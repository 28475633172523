import React, { useContext } from 'react'
import styled from 'styled-components'
import { theme } from '../../../../utils/theme'
import {
	CenteredSection,
	CenteredSmallPageSection,
	SmallPaddingWrapper,
	SmallPageSection,
	SmallPageSectionRepulsiveRow,
} from '../../../reusable_components/layout/PageComponents'
import RadioButtonChecked from '../../../reusable_components/svg_icons/RadioButtonChecked'
import RadioButton from '../../../reusable_components/svg_icons/RadioButton'
import { Markdown, P2, P5 } from '../../../reusable_components/Typography'
import UserContext from '../../../../contexts/user/UserContext'
import useRoutes from '../../../../hooks/useRoutes'

const TierWrapper = styled(SmallPaddingWrapper)`
	text-align: left; // Keep this if the modal is in a CenteredSection
	border-radius: 5px;
	cursor: ${({ isSelected }) => (isSelected ? 'auto' : 'pointer')};
	border: 1px solid ${({ isSelected }) => (isSelected ? theme.colors.black : theme.colors.gray)};
	pointer-events: ${({ isCurrentHostTier }) => (isCurrentHostTier ? 'none' : 'auto')};
	* {
		color: ${({ isCurrentHostTier }) => (isCurrentHostTier ? theme.colors.lightTextColor : theme.colors.textColor)};
	}
`

const UpgradeTier = props => {
	let { t, setSelectedTier, selectedTier, title, hostTier, hideReadMoreLink } = props
	const routes = useRoutes()
	const { userState, dispatch } = useContext(UserContext)
	if (!selectedTier) selectedTier = userState.tier
	if (!setSelectedTier)
		setSelectedTier = newTier => dispatch({ type: 'SESSION_MODAL_DISPLAY_STATE', data: { tier: newTier } })
	const tierBoxProps = { selectedTier, setSelectedTier, t, hostTier }

	return (
		<>
			{title && (
				<>
					<CenteredSection>
						<P2>{title}</P2>
					</CenteredSection>
					<br />
					<br />
				</>
			)}
			{/* Ony show TierBoxes for tiers above the current tier, i.e the ones the host can upgrade to */}
			{(!hostTier || ['no_tier', 'free'].includes(hostTier)) && (
				<>
					<TierBox {...tierBoxProps} tier="free" />
					<br />
				</>
			)}
			{(!hostTier || ['no_tier', 'free', 'standard'].includes(hostTier)) && (
				<>
					<TierBox {...tierBoxProps} tier="standard" />
					<br />
				</>
			)}
			<TierBox {...tierBoxProps} tier="pro" />
			<CenteredSmallPageSection>
				<P5>{t('admin:upgradeTier.transactionFee')}</P5>
			</CenteredSmallPageSection>
			{hideReadMoreLink || (
				<CenteredSmallPageSection>
					<Markdown linkTarget="_blank">{t('admin:upgradeTier.readMore', { url: routes.static.pricing.url })}</Markdown>
				</CenteredSmallPageSection>
			)}
		</>
	)
}

const TierBox = props => {
	const { selectedTier, setSelectedTier, t, tier, hostTier } = props
	const isSelected = selectedTier === tier
	const isCurrentHostTier = tier === hostTier
	return (
		<TierWrapper isCurrentHostTier={isCurrentHostTier} isSelected={isSelected} onClick={() => setSelectedTier(tier)}>
			<P2>{t('admin:upgradeTier.name.' + tier)}</P2>
			<SmallPageSectionRepulsiveRow>
				{/*{tier === 'pro' ? (
					<p>{t('admin:upgradeTier.contactForPrice')}</p>
				) : (*/}
					<p>{t('admin:upgradeTier.monthlyPrice.' + tier)}&nbsp;*</p>
				 {/*)}*/}
				{isSelected ? <RadioButtonChecked /> : <RadioButton />}
			</SmallPageSectionRepulsiveRow>
			<SmallPageSection>
				<p>{t('admin:upgradeTier.description.' + tier)}</p>
			</SmallPageSection>
		</TierWrapper>
	)
}

export default UpgradeTier
