import React, { useState } from 'react'
import copyToClipboard from '../../../utils/copyToClipboard'
import { B4 } from '../Typography'
import styled from 'styled-components'
import Copy from '../svg_icons/Copy'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next-new'

const StyledCopyToClipboard = styled.div`
	position: relative;
	display: inline-flex;
	width: ${props => (props.wide ? '100%' : 'fit-content')};
	> * {
		cursor: pointer;
	}
`
const CopiedAlert = styled.div`
	${({ theme, displayCopiedAlert, position }) => `
    opacity: ${displayCopiedAlert ? 1 : 0};
    pointer-events: none;
    transition: opacity 0.4s;
    position: absolute;
    user-select: none;
    transform: translate(-65%, calc(-100% - ${theme.paddings.XXSmall}));
    ${position === 'right' ? 'left: calc(100%);' : 'right: 0;'}
    top: 0;
    background: ${theme.colors.black};
    border-radius: 5px;
    padding: ${theme.paddings.XXSmall} ${theme.paddings.XSmall};
    cursor: default;
    width: max-content;
    > * {
      color: white;
    }
  `}
`

// ID is used for analytics tracking
function CopyToClipboardButton({ children, textToCopy = '', id, position = 'right', copiedAlertText, ...rest }) {
	const { t } = useTranslation()
	const [displayCopiedAlert, setDisplayCopiedAlert] = useState(false)
	return (
		<StyledCopyToClipboard
			onClick={() => {
				setDisplayCopiedAlert(true)
				if (!displayCopiedAlert) {
					setTimeout(() => {
						setDisplayCopiedAlert(false)
					}, 1500)
				}
				copyToClipboard(textToCopy)
			}}
			{...rest}
		>
			{children || <Copy id={id} width={20} height={20} />}
			<CopiedAlert displayCopiedAlert={displayCopiedAlert} position={position}>
				<B4>{copiedAlertText || t('common:buttons.copied')}</B4>
			</CopiedAlert>
		</StyledCopyToClipboard>
	)
}

export default CopyToClipboardButton

CopyToClipboardButton.propTypes = {
	textToCopy: PropTypes.string,
	copiedAlertText: PropTypes.string,
	wide: PropTypes.bool,
	position: PropTypes.string, // The "right" option can be too far right on e.g. MobileModal, which makes the CopiedAlert go outside of the screen
}
