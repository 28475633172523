import React, { useEffect, useState } from 'react'
import {
	CenteredPageSection,
	CenteredSection,
	PageSidePaddingWrapper,
	XSmallPageSection,
} from '../../reusable_components/layout/PageComponents'
import HelmetHead from '../../layout/HelmetHead'
import { ContentSliderImage } from '../../courses/show/ContentSlider'
import { HeaderWrapper } from '../../courses/show/HeaderSection'
import styled from 'styled-components'
import CourseCardsWrapper from '../../reusable_components/course_cards/CourseCardsWrapper'
import { theme } from '../../../utils/theme'
import Button from '../../reusable_components/buttons/Button'
import { Link } from 'react-router-dom'
import { noMarginForFirstAndLastChild } from '../../reusable_components/PageMixins'
import { gql, useQuery } from 'urql'
import LoadContainer from '../../admin/layout/LoadContainer'
import SaFrontendComponent from '../../reusable_components/other/sa_frontend_components/SaFrontendComponent'
import SubCategoryLinks from '../../categories/show/SubCategoryLinks'

export const FixedHeader = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
`

// Needed below a fixed header to make sure the content below it is put on top of the fixed header
export const BelowFixedHeader = styled.div`
	background: white;
	padding-bottom: ${theme.paddings
		.large}; // Padding is needed at the bottom to prevent the fixed header no to be visible between last element in BelowFixedHeader and footer. Last element in BelowFixedHeader shouldn't have bottom margin.
	position: relative;
	z-index: 2;
	transition: box-shadow 0.5s;
	box-shadow: none;
	${noMarginForFirstAndLastChild}
`
const StyledFixedHeaderPlaceHolder = styled.div`
	opacity: ${({ percentageScrolled }) => percentageScrolled};
	pointer-events: none;
	position: relative;
	z-index: 2;
	background: ${theme.colors.black};
	transition: opacity 0.5s;
`

let htmlBody, fixedHeaderPlaceholder, headerRef, belowFixedHeader
// Needed to push down the content below the fixed header. Has the same height as the fixed header
export const FixedHeaderPlaceHolder = ({ children }) => {
	const [percentageScrolled, setPercentageScrolled] = useState(0)
	// Dim out the header as the user scrolls down
	const scrollToTopListener = () => {
		if (!htmlBody || !fixedHeaderPlaceholder || !headerRef) return
		let newPercentageScrolled = Number(
			(
				htmlBody.scrollTop /
				(fixedHeaderPlaceholder.getBoundingClientRect().height - headerRef.getBoundingClientRect().height)
			).toFixed(1),
		)
		if (newPercentageScrolled > 1) newPercentageScrolled = 1
		setPercentageScrolled(newPercentageScrolled)
	}
	useEffect(() => {
		htmlBody = document.getElementsByTagName('html')[0]
		fixedHeaderPlaceholder = document.getElementById('FIXED_HEADER_PLACEHOLDER')
		headerRef = document.getElementById('HEADER_OPACITY_DIV')
		belowFixedHeader = document.getElementById('BELOW_FIXED_HEADER')
		scrollToTopListener()
		window.addEventListener('scroll', scrollToTopListener)
		return () => {
			window.removeEventListener('scroll', scrollToTopListener)
		}
	}, [])
	useEffect(() => {
		if (!belowFixedHeader) return
		if (percentageScrolled === 0) {
			// Remove box shadow when scrolled to top
			belowFixedHeader.style.removeProperty('box-shadow')
		} else if (!belowFixedHeader.style.boxShadow) {
			// Add a box shadow when scrolling down
			belowFixedHeader.style.boxShadow = 'rgb(0 0 0 / 20%) 0px -30px 25px' // If changing this, also change in onInputBlur in BigSearchBar
		}
	}, [percentageScrolled])
	return (
		<StyledFixedHeaderPlaceHolder percentageScrolled={percentageScrolled} id="FIXED_HEADER_PLACEHOLDER">
			{children || <ContentSliderImage />}
		</StyledFixedHeaderPlaceHolder>
	)
}

const MarketplacePageContent = ({ ssrCourseCardData, t, routes, marketplace }) => {
	const { id, title, ogTitle, description, ogDescription, image, frontendComponentsData, topCategoriesData, coursesPath } = marketplace
	const [fetchedCourseIds, setFetchedCourseIds] = useState(
		ssrCourseCardData?.courseCards ? ssrCourseCardData?.courseCards?.slice(0, 4).map(courseCard => courseCard.id) : [], // Slice first 4 if the ssr is loaded from e.g. all courses page, otherwise we will exclude too much
	)
	// Update the fetchedCourseIds everytime courses gets fetched on this page so we don't display the same ones multiple times
	const onFetch = moreFetchedCourseIds => setFetchedCourseIds([...fetchedCourseIds, ...moreFetchedCourseIds])
	return (
		<>
			<HelmetHead title={ogTitle} description={ogDescription} image={image} topLevelHelmet />
			<FixedHeader id="FIXED_DIV">
				<HeaderWrapper>
					<ContentSliderImage image={image} alt={title} />
					<div>
						<div>
							<PageSidePaddingWrapper>
								<CenteredSection>
									<h1>{title}</h1>
									<p>{description}</p>
								</CenteredSection>
							</PageSidePaddingWrapper>
							<XSmallPageSection />
							<SubCategoryLinks
								type="CATEGORY_NO_FILTER"
								t={t}
								categories={topCategoriesData}
								seeMoreLink={coursesPath}
							/>
						</div>
					</div>
				</HeaderWrapper>
			</FixedHeader>
			<FixedHeaderPlaceHolder />
			<BelowFixedHeader style={{ paddingTop: theme.paddings.large }} id="BELOW_FIXED_HEADER">
				<PageSidePaddingWrapper>
					<CourseCardsWrapper
						type="marketplace"
						id={id}
						optimizeRows
						quantity={4}
						title={t('copy:marketplacePage.courseCardsTitle')}
						ssrCourseCardData={ssrCourseCardData}
						onFetch={onFetch}
					/>
					<CenteredPageSection>
						<Link to={coursesPath}>
							<Button color="OUTLINED_BLACK">{t('common:buttons.wholeOffering')}</Button>
						</Link>
					</CenteredPageSection>
				</PageSidePaddingWrapper>
				{frontendComponentsData.map((frontendComponent, key) => (
					<SaFrontendComponent
						key={key}
						frontendComponent={frontendComponent}
						parentId={id}
						parentType="MARKETPLACE"
						fetchedCourseIds={fetchedCourseIds}
						onFetch={onFetch}
					/>
				))}
			</BelowFixedHeader>
		</>
	)
}

// OBS: Whatever changes you make to the attributes here for getMarketplace also need to be applied to the memorized_page_model in marketplace.rb
export const getMarketplaceGql = gql`
	query ($id: String!) {
		marketplace(slug: $id) {
			id
			slug
			title
			description
			ogTitle
			ogDescription
			categorySummary
			image
			coursesPath
			path
			frontendComponentsData
			topCategoriesData
			featuredCourseCards
		}
	}
`
const MarketplacePageWithGraphQl = props => {
	const [result] = useQuery({
		query: getMarketplaceGql,
		variables: { id: props.id },
	})
	const { data, fetching, error } = result
	if (fetching || error) {
		// When loading a marketplace with graphql the page becomes white, since marketplace page fills to top with a transparent header when it has an image ready, which makes the header's text white and thus not visible against the white background when no image is ready.
		// Therefore we temporarily make the header not transparent until the marketplace has loaded
		window.setHeaderFillPageToTop(false)
		return (
			<div style={{ marginTop: theme.margins.XLarge }}>
				<LoadContainer error={error} fetching={true} />
			</div>
		)
	}
	window.setHeaderFillPageToTop(true)
	return <MarketplacePageContent {...props} marketplace={data.marketplace} />
}

const MarketplacePage = props => {
	const marketplace = props.memorizedPageModel
	// If page was server side rendered, then render it. Otherwise get the current marketplace with graphQL instead
	return marketplace ? (
		<MarketplacePageContent {...props} marketplace={marketplace} />
	) : (
		<MarketplacePageWithGraphQl {...props} />
	)
}

export default MarketplacePage
