import React, { useCallback, useEffect, useState } from 'react'
import {
	CenteredPageSection,
	CenteredSection,
	PageSidePaddingWrapper,
} from '../../reusable_components/layout/PageComponents'
import HelmetHead from '../../layout/HelmetHead'
import { getRootDomainStartPageData, isRootDomain } from '../../../utils/applicationHelper'
import { theme } from '../../../utils/theme'
import styled from 'styled-components'
import ReusableSlider from '../../reusable_components/other/ReusableSlider'
import ConditionalLink from '../../reusable_components/other/ConditionalLink'
import BigSearchBar from './BigSearchBar'
import CourseCardsWrapper from '../../reusable_components/course_cards/CourseCardsWrapper'
import { Link } from 'react-router-dom'
import Button from '../../reusable_components/buttons/Button'
import SaFrontendComponent from '../../reusable_components/other/sa_frontend_components/SaFrontendComponent'
import { getCloudinaryImageUrl, getCloudinaryVideoUrl } from '../../../utils/imageHelper'
import Breakpoint from '../../reusable_components/responsive/Breakpoint'
import { BelowFixedHeader, FixedHeader, FixedHeaderPlaceHolder } from '../show/MarketplacePage'
import { TagsWrapperMobile } from '../../sub_categories/show/SkillTags'
import { P5 } from '../../reusable_components/Typography'

// The FIXED_DIV where the big search bar is is in a parent with lower z-index than the rest of the page, so the search results are below the rest of the page
// Therefore we add this div outside of FIXED_DIV and the use reactPortal to render the search results to it
const SearchResults = styled.div`
	position: absolute;
	.react-autosuggest__suggestions-container {
		opacity: 0;
		height: 0;
		transition:
			opacity 0.3s 0.2s,
			height 0.3s;
	}
	.react-autosuggest__suggestions-container--open {
		opacity: 1;
		box-shadow: rgb(128 128 128 / 30%) 0 16px 15px 0;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		border: none;
		position: fixed;
		z-index: 3;
		background: white;
		overflow-y: scroll;
		&::-webkit-scrollbar {
			display: none;
		}
		-ms-overflow-style: none;
		scrollbar-width: none;
		padding-bottom: ${theme.paddings.XSmall};
	}
`

const videoPaddingMobile = 150,
	videoPaddingDesktop = 56.25
const VideoWrapper = styled.div`
	overflow: hidden;
	position: relative;
	height: 0;
	width: 100%;
	${theme.breakpoints.tablet.upToAndIncluding} {
		padding-bottom: ${videoPaddingMobile}%;
		background-image: url('${getCloudinaryImageUrl(
			'ejbla_marketplace_mobile_placeholder',
			200,
			'ORIGINAL_RATIO',
			',e_blur',
		)}');
	}
	${theme.breakpoints.tablet.overAndExcluding} {
		padding-bottom: ${videoPaddingDesktop}%;
		background-image: url('${getCloudinaryImageUrl(
			'ejbla_marketplace_desktop_placeholder',
			400,
			'ORIGINAL_RATIO',
			',e_blur',
		)}');
	}
	background-size: cover;
	> video {
		opacity: ${({ loaded }) => (loaded ? 1 : 0)};
		transition: opacity 0.4s;
		position: absolute;
		width: 100%;
	}
	> div {
		background: #0000003d;
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		> div {
			:first-child {
				${theme.breakpoints.tablet.upToAndIncluding} {
					margin-top: 20px;
				}
				${theme.breakpoints.tablet.overAndExcluding} {
					margin-top: -20px;
				}
				margin-bottom: ${theme.margins.large};
			}
			> div:first-child {
				color: white;
				${theme.breakpoints.tablet.overAndExcluding} {
					h1 {
						font-size: 50px;
						line-height: 60px;
					}
				}
			}
		}
	}
`
const VideoPlaceholderWrapper = styled.div`
	overflow: hidden;
	height: 0;
	width: 100%;
	${theme.breakpoints.tablet.upToAndIncluding} {
		padding-bottom: ${videoPaddingMobile}%;
	}
	${theme.breakpoints.tablet.overAndExcluding} {
		padding-bottom: ${videoPaddingDesktop}%;
	}
`

const MarketplacesPage = ({ t, footerLinks, routes, ssrCourseCardData }) => {
	const { title, ogTitle, description, ogDescription, image, frontendComponents } = getRootDomainStartPageData()
	const [videoLoaded, setVideoLoaded] = useState(false)
	const [searchResultsElement, setSearchResultsElement] = useState(null)
	const [fetchedCourseIds, setFetchedCourseIds] = useState(
		ssrCourseCardData?.courseCards ? ssrCourseCardData?.courseCards?.slice(0, 4).map(courseCard => courseCard.id) : [], // Slice first 4 if the ssr is loaded from e.g. all courses page, otherwise we will exclude too much
	)
	useEffect(() => {
		const video = document.getElementById('HEADER_VIDEO')
		if (video) {
			video.addEventListener(
				'loadeddata',
				() => {
					setVideoLoaded(true)
				},
				false,
			)
			video.load() // Event listener loadeddata isn't triggered unless we do load(), even though we have autoPlay
		}
	}, [])
	// Update the fetchedCourseIds everytime courses gets fetched on this page so we don't display the same ones multiple times
	const onFetch = moreFetchedCourseIds => setFetchedCourseIds([...fetchedCourseIds, ...moreFetchedCourseIds])
	const ref = useCallback(node => setSearchResultsElement(node), [])
	const getVideoUrl = type => getCloudinaryVideoUrl(`videos/ejbla_marketplace_${type}`)
	return (
		<>
			<HelmetHead title={ogTitle} description={ogDescription} image={image} topLevelHelmet />
			<FixedHeader id="FIXED_DIV">
				<VideoWrapper loaded={videoLoaded}>
					<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>
						<video id="HEADER_VIDEO" preload="auto" muted loop autoPlay playsInline width="100%" height="100%">
							<source src={getVideoUrl('mobile')} />
						</video>
					</Breakpoint>
					<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
						<video id="HEADER_VIDEO" preload="auto" muted loop autoPlay playsInline width="100%" height="100%">
							<source src={getVideoUrl('desktop')} />
						</video>
					</Breakpoint>
					<div>
						<PageSidePaddingWrapper>
							<CenteredSection>
								<h1>{title}</h1>
								<p>{description}</p>
								<BigSearchBar t={t} searchResultsElement={searchResultsElement} />
							</CenteredSection>
						</PageSidePaddingWrapper>
						<MarketplaceSlider marketplaces={footerLinks.marketplaces} />
					</div>
				</VideoWrapper>
			</FixedHeader>
			<FixedHeaderPlaceHolder>
				<VideoPlaceholderWrapper />
			</FixedHeaderPlaceHolder>
			<SearchResults ref={ref} />
			<BelowFixedHeader style={{ paddingTop: theme.paddings.large }} id="BELOW_FIXED_HEADER">
				<PageSidePaddingWrapper>
					<CourseCardsWrapper
						type="workshops"
						optimizeRows
						quantity={4}
						title={t('copy:marketplacePage.courseCardsTitle')}
						ssrCourseCardData={ssrCourseCardData}
						onFetch={onFetch}
					/>
					<CenteredPageSection>
						<Link to={routes.course.index.path}>
							<Button color="OUTLINED_BLACK">{t('common:buttons.wholeOffering')}</Button>
						</Link>
					</CenteredPageSection>
				</PageSidePaddingWrapper>
				{frontendComponents.map((frontendComponent, key) => (
					<SaFrontendComponent
						key={key}
						frontendComponent={frontendComponent}
						fetchedCourseIds={fetchedCourseIds}
						onFetch={onFetch}
					/>
				))}
			</BelowFixedHeader>
		</>
	)
}

const MarketplacesDesktopWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: ${theme.margins.small};
	align-items: center;
	position: relative;
	max-width: 500px;
`

const MarketplaceSlider = ({ marketplaces }) => {
	return (
		<>
			<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>
				<MarketplaceSliderMobile marketplaces={marketplaces} />
			</Breakpoint>
			<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
				<MarketplacesDesktopWrapper>
					{marketplaces.map((marketplace, key) => (
						<MarketplaceBadge marketplace={marketplace} key={key} />
					))}
				</MarketplacesDesktopWrapper>
			</Breakpoint>
		</>
	)
}
const MarketplaceBadge = ({ marketplace }) => {
	return (
		<ConditionalLink to={isRootDomain() ? marketplace.path : marketplace.url}>
			<Button buttonType="ROUND" color="WHITE">
				<P5>{marketplace.title}</P5>
			</Button>
		</ConditionalLink>
	)
}
const MarketplaceSliderMobile = ({ marketplaces }) => {
	const [loaded, setLoaded] = useState(false)
	useEffect(() => {
		// The slider loads weird on mobile (especially when many tags exist), so let it fully render first and then show it
		setLoaded(true)
	}, [])
	return (
		<TagsWrapperMobile loaded={loaded}>
			<ReusableSlider desktopSlidesToShow={5} settings={{ variableWidth: true }}>
				{marketplaces.map((marketplace, key) => (
					<MarketplaceBadge marketplace={marketplace} key={key} />
				))}
			</ReusableSlider>
		</TagsWrapperMobile>
	)
}

export default MarketplacesPage
