import { createGlobalStyle } from 'styled-components'
import { theme } from '../../utils/theme'

const AutoSuggestStyle = createGlobalStyle`
    .react-autosuggest__container {
        position: relative;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
    }

    .react-autosuggest__input {
        -webkit-appearance: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 14px;
        font-weight: 300;
        border: none;
        padding-left: 25px;
        background: transparent;
    }

    .react-autosuggest__input:focus {
        outline: none;
    }

    .react-autosuggest__container--open .react-autosuggest__input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .react-autosuggest__container--open .react-autosuggest__suggestions-container {
        display: block;
        position: absolute;
        width: 100%;
        border: none;
        background: white;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        z-index: 2;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none;
        scrollbar-width: none;
        box-shadow: rgb(128 128 128 / 30%) 0 16px 15px 0;
        padding-bottom: ${theme.paddings.XSmall};
    }

    .react-autosuggest__suggestions-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .react-autosuggest__suggestion {
        cursor: pointer;
        padding: ${theme.paddings.XXSmall} ${theme.paddings.small};
        transform: translateY(0);
        transition: transform .2s;
        img {
            transition: box-shadow .2s;
            box-shadow: none;
        }
    }

    .react-autosuggest__suggestion--highlighted {
        background-color: ${theme.colors.gray};
        transform: translateY(-1px);
        img {
            box-shadow: rgba(0, 0, 0, 0.35) 1px 1px 9px 0px;
        }
    }

    .react-autosuggest__section-container:last-child .react-autosuggest__suggestion:last-child.react-autosuggest__suggestion--highlighted {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .react-autosuggest__section-title {
        padding: ${theme.paddings.XSmall} ${theme.paddings.small} ${theme.paddings.XXSmall};
        font-size: ${theme.fontSizes.p3};
    }

    .react-autosuggest__more-courses-link {
        padding: ${theme.paddings.XSmall} ${theme.paddings.small};
        text-decoration: underline;
        color: ${theme.colors.lightTextColor};
        cursor: pointer;
    }
`

export default AutoSuggestStyle
