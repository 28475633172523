import React from 'react'
import ConditionalLink from '../other/ConditionalLink'
import { isHostSignupDomain } from '../../../utils/applicationHelper'
import useRoutes from '../../../hooks/useRoutes'

const HostSignupLogo = ({ fillColor = '#111111', onClick, disableClick }) => {
	const routes = useRoutes()
	return (
		<ConditionalLink
			to={routes.static.hostSignupDomainStartPage[isHostSignupDomain() ? 'path' : 'url']}
			onClick={onClick}
			style={{ display: 'flex', pointerEvents: disableClick ? 'none' : 'auto' }}
		>
			<svg width="72" height="35" xmlns="http://www.w3.org/2000/svg">
				<title>ejblalogo</title>
				<text fontFamily="Chap" x="0" y="27" fill={fillColor} fontSize="35" fontStyle="normal" fontWeight="normal">
					ejbla
				</text>
			</svg>
		</ConditionalLink>
	)
	/*
    return (
      <ConditionalLink
        to={routes.static.hostSignupDomainStartPage[isHostSignupDomain() ? 'path' : 'url']}
        onClick={onClick}
        style={{ display: 'flex', pointerEvents: disableClick ? 'none' : 'auto' }}
      >
        <div style={{ fontFamily: 'Edo', color: fillColor, textAlign: 'center' }}>
          <div style={{ fontSize: '30px' }}>join</div>
          <div>Ejbla</div>
        </div>
      </ConditionalLink>
    )
  */
}

export default HostSignupLogo
