import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { theme } from '../../../../utils/theme'
import { hideScrollbar } from '../../PageMixins'
import { isModalOpen } from '../../../../utils/applicationHelper'
import useLocation from '../../../../hooks/useLocation'
import { disableBodyScroll, enableBodyScroll } from '../../../../utils/disableBodyScroll'

const Wrapper = styled.div`
	z-index: 8;
	position: fixed;
	overflow-y: scroll;
	top: 0;
	outline: none;
	max-width: ${({ maxWidth }) => maxWidth}px;
	width: 90%;
	${theme.breakpoints.tablet.upToAndIncluding} {
		width: 100%;
	}
	${theme.breakpoints.phone.range} {
		${({ position }) => (position === 'LEFT' ? 'left' : 'right')}: ${({ isOpen }) =>
			isOpen ? 0 : -theme.breakpoints.phone.maxInt}px;
	}
	${theme.breakpoints.tablet.range} {
		${({ position }) => (position === 'LEFT' ? 'left' : 'right')}: ${({ isOpen }) =>
			isOpen ? 0 : -theme.breakpoints.tablet.maxInt}px;
	}
	${theme.breakpoints.tablet.overAndExcluding} {
		${({ position }) => (position === 'LEFT' ? 'left' : 'right')}: ${({ isOpen, maxWidth }) =>
			isOpen ? 0 : -maxWidth}px;
	}
	bottom: 0;
	transition:
		left 0.5s cubic-bezier(0.4, 0, 0.2, 1),
		right 0.5s cubic-bezier(0.4, 0, 0.2, 1);
	background: ${({ sideBarColor }) => sideBarColor};
	${({ displayScrollbar }) => (displayScrollbar ? '' : hideScrollbar)}
`
const DimmedBackground = styled.div`
	z-index: 8;
	position: fixed;
	opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #000000b0;
	pointer-events: none;
	transition: opacity 0.5s;
`

const SideBar = ({
	isOpen,
	handleClose,
	children,
	position = 'LEFT',
	color = theme.colors.black,
	maxWidth,
	displayScrollbar = false,
}) => {
	const colors = getColors(color)
	const location = useLocation()
	let sideBarRef = useRef(null)

	// Scroll to the top of the SideBar when the content changes, e.g. it is opened but a course card is clicked within the SideBar so the URL and content updates
	const currentPath = location.pathname
	useEffect(() => {
		sideBarRef.current?.scrollTo(0, 0)
	}, [currentPath])

	// Not using capture true makes the useCallback in ResponsiveImage trigger this event listener for some reason
	const eventListenerOptions = { capture: true }
	useEffect(() => {
		// Add a popstate listener to close the side bar when the user clicks the browser's back button
		const popStateListener = () => {
			handleClose(false)
		}
		// Add a click listener to close the side bar when the user clicks outside of it (except if an overlay modal is open)
		const clickListener = event => {
			const overlayModalIsOpen = isModalOpen()
			if (isOpen && !sideBarRef.current?.contains(event.target) && !overlayModalIsOpen) {
				// Make sure the user isn't able to click something by mistake (e.g. a course card) when clicking outside the side bar with the intention to close it
				event.preventDefault()
				event.stopPropagation()
				handleClose(true)
			}
		}
		// Add a keyup listener to close the side bar when the user clicks the 'esc' button (except if an overlay modal is open)
		const keyBoardListener = event => {
			const overlayModalIsOpen = isModalOpen()
			if (!overlayModalIsOpen && event.keyCode === 27) {
				handleClose(true)
			}
		}
		// Add a swipe listener to close the side bar when the user swipes right/left on mobile (except if an overlay modal is open)
		const swipeListener = event => {
			const overlayModalIsOpen = isModalOpen()
			if (!overlayModalIsOpen) {
				handleClose(true)
			}
		}
		if (isOpen) {
			// Focus the side bar when it opens so it can be scrolled up/down with the arrow keys.
			// tabIndex -1 (on the scrollable Wrapper html element) is needed to let the browser know it can be focused after it appears.
			// We also hide the browser's outline with css in the styled component.
			sideBarRef.current.focus()
			// Disable scroll on html behind it when opened
			disableBodyScroll(sideBarRef.current)
			window.addEventListener('popstate', popStateListener)
			window.addEventListener('click', clickListener, eventListenerOptions)
			window.addEventListener('keyup', keyBoardListener)
			document.addEventListener(`swiped-${position.toLowerCase()}`, swipeListener)
		}
		return () => {
			enableBodyScroll(sideBarRef.current)
			window.removeEventListener('popstate', popStateListener)
			window.removeEventListener('click', clickListener, eventListenerOptions)
			window.removeEventListener('keyup', keyBoardListener)
			document.removeEventListener(`swiped-${position.toLowerCase()}`, swipeListener)
		}
	}, [isOpen])
	return (
		<>
			<DimmedBackground isOpen={isOpen} />
			<Wrapper
				tabIndex={-1}
				isOpen={isOpen}
				ref={sideBarRef}
				position={position}
				maxWidth={maxWidth}
				displayScrollbar={displayScrollbar}
				id={isOpen ? 'SIDEBAR_OPEN' : 'SIDEBAR_CLOSED'}
				{...colors}
			>
				{children}
			</Wrapper>
		</>
	)
}

const getColors = color => {
	let sideBarColor
	switch (color) {
		case 'WHITE':
			sideBarColor = 'white'
			break
		default:
			sideBarColor = theme.colors.black
	}
	return { sideBarColor }
}

export default SideBar
