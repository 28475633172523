import PropTypes from 'prop-types'
import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { theme } from '../../../utils/theme'

const LoaderAnimationKeyFrames = keyframes`
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`
const StyledLoaderAnimation = styled.div`
	display: flex;
	height: 15px;
	align-items: center;
	width: fit-content;
	margin: auto;
	> div {
		width: 15px;
		height: 15px;
		background-color: ${({ color }) => color};
		border-radius: 50%;
		animation: ${LoaderAnimationKeyFrames} 1.4s infinite ease-in-out;
		:nth-child(1) {
			animation-delay: -0.32s;
		}
		:nth-child(2) {
			animation-delay: -0.16s;
		}
		:not(:last-child) {
			margin-right: 3px;
		}
	}
`

export const LoaderAnimation = props => (
	<StyledLoaderAnimation {...props}>
		<div />
		<div />
		<div />
	</StyledLoaderAnimation>
)

const ButtonContent = styled.div`
	${({ hasOwnStyling, submitted }) =>
		(submitted || !hasOwnStyling) &&
		css`
			display: flex;
			align-items: center;
			justify-content: center;
			> *:not(:last-child) {
				margin-right: ${theme.margins.XSmall};
			}
		`}
`
const ButtonWrapper = styled.button`
	${({
		padding,
		disable,
		disabledColor,
		wide,
		hideDisabledCursor,
		buttonColor,
		borderRadius,
		textColor,
		borderColor,
		buttonType,
	}) => css`
		outline: none;
		border: ${borderColor ? `1px solid ${borderColor}` : 'none'};
		padding: ${padding};
		${wide && 'width: 100%;'}
		&:focus {
			outline: none;
		}
		line-height: 1;
		* {
			line-height: 1;
		}
		${ButtonContent} {
			color: ${disable ? theme.colors.lightTextColor : textColor};
			text-decoration: ${buttonType === 'LINK' ? 'underline' : 'none'};
		}
		background-color: ${disable && buttonColor !== 'transparent' ? disabledColor || theme.colors.gray : buttonColor};
		border-radius: ${borderRadius}px;
		cursor: ${disable && hideDisabledCursor ? 'auto' : 'pointer'};
	`}
`
const Button = React.memo(
	React.forwardRef(function button(
		{
			children,
			text,
			submitted,
			disable,
			color = 'BLACK',
			buttonType = 'ROUND',
			hasOwnStyling = false,
			gtmId,
			excludeFromForm,
			excludeFromFormSubmit,
			onClick,
			...rest
		},
		ref,
	) {
		const colors = getButtonColors(color)
		const typeAttributes = getTypeAttributes(buttonType)
		return (
			<ButtonWrapper
				id={gtmId}
				disable={disable}
				buttonType={buttonType}
				onClick={disable ? () => {} : onClick}
				{...rest}
				{...colors}
				{...typeAttributes}
				ref={ref}
				data-disable={disable}
				type={excludeFromForm || excludeFromFormSubmit ? 'button' : undefined}
			>
				<ButtonContent hasOwnStyling={hasOwnStyling} submitted={submitted}>
					{submitted ? <LoaderAnimation color={colors.textColor} /> : children || text}
				</ButtonContent>
			</ButtonWrapper>
		)
	}),
)

Button.propTypes = {
	text: PropTypes.string,
	gtmId: PropTypes.string, // Used for GTM tracking
	disabledColor: PropTypes.string, // Specify a button color for when disabled if the automatically chosen disabled color doesn't fit
	excludeFromForm: PropTypes.bool, // Set type to 'button' to prevent a button from submitting a form, and also prevent it from getting automatically disabled when not all required fields are filled in
	excludeFromFormSubmit: PropTypes.bool, // Set type to 'button' to prevent a button from submitting a form, but still allow it to get automatically disabled when not all required fields are filled in.
	onClick: PropTypes.func,
	submitted: PropTypes.bool,
	disable: PropTypes.bool,
	hasOwnStyling: PropTypes.bool, // Defaults to false. If true, the parent sets a custom styling for the button content
	hideDisabledCursor: PropTypes.bool, // Hides cursor pointer when button is disabled to make it clear it can't be clicked
	wide: PropTypes.bool,
	// buttonType: PropTypes.oneOf(['ROUND', 'RECTANGLE', 'LINK']),
	/*color: PropTypes.oneOf([
		'WHITE',
		'BLACK',
		'ORANGE',
		'GRAY',
		'GOLD',
		'OUTLINED_BLACK',
		'OUTLINED_WHITE',
		'TRANSPARENT_BLACK',
		'TRANSPARENT_WHITE',
	]),*/
}

const getButtonColors = color => {
	let buttonColor, textColor, borderColor
	switch (color) {
		case 'WHITE':
			buttonColor = 'white'
			borderColor = 'transparent' // Give a transparent border color so the height is the same for outlined and non-outlined buttons when toggling them back and forth
			textColor = theme.colors.textColor
			break
		case 'BLACK':
			buttonColor = theme.colors.black
			borderColor = 'transparent' // Give a transparent border color so the height is the same for outlined and non-outlined buttons when toggling them back and forth
			textColor = 'white'
			break
		case 'OUTLINED_BLACK':
			buttonColor = 'white'
			textColor = theme.colors.black
			borderColor = theme.colors.black
			break
		case 'OUTLINED_WHITE':
			buttonColor = theme.colors.black
			textColor = theme.colors.white
			borderColor = theme.colors.white
			break
		case 'ORANGE':
			buttonColor = theme.colors.orange
			borderColor = 'transparent' // Give a transparent border color so the height is the same for outlined and non-outlined buttons when toggling them back and forth
			textColor = 'white'
			break
		case 'GRAY':
			buttonColor = theme.colors.lightGray
			borderColor = 'transparent' // Give a transparent border color so the height is the same for outlined and non-outlined buttons when toggling them back and forth
			textColor = theme.colors.black
			break
		case 'GOLD':
			buttonColor = theme.colors.gold
			borderColor = 'transparent' // Give a transparent border color so the height is the same for outlined and non-outlined buttons when toggling them back and forth
			textColor = 'white'
			break
		case 'TRANSPARENT_BLACK':
			buttonColor = 'transparent'
			textColor = theme.colors.textColor
			break
		case 'TRANSPARENT_WHITE':
			buttonColor = 'transparent'
			textColor = 'white'
			break
		default:
	}
	return { buttonColor, textColor, borderColor }
}

const getTypeAttributes = buttonType => {
	let borderRadius, padding
	switch (buttonType) {
		case 'ROUND':
			borderRadius = 100
			padding = `${theme.paddings.XSmall} ${theme.paddings.small}`
			break
		case 'ROUND_THIN':
			borderRadius = 100
			padding = `7px 16px`
			break
		case 'RECTANGLE':
			borderRadius = 2
			padding = theme.paddings.small
			break
		case 'LINK':
			borderRadius = 0
			padding = 0
			break
		case 'LINK_NO_UNDERLINE':
			borderRadius = 0
			padding = 0
			break
		default:
	}
	return { borderRadius, padding }
}

export default Button
