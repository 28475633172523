import styled, { css } from 'styled-components'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next-new'

import {
	ContentRow,
	SmallPageSection,
	SmallPageSectionDivider,
	SmallPageSectionRepulsiveRow,
} from '../layout/PageComponents'
import { CallToAction, P2, P3, P4, P5, UnderlinedA } from '../Typography'
import { theme } from '../../../utils/theme'
import User from '../svg_icons/User'
import NonResponsiveImage from '../responsive/NonResponsiveImage'
import Fire from '../svg_icons/Fire'
import Diamond from '../svg_icons/Diamond'
import { limitRows } from '../PageMixins'
import Lightning from '../svg_icons/Lightning'
import Group from '../svg_icons/Group'
import Gift from '../svg_icons/Gift'
import Clock from '../svg_icons/Clock'
import Language from '../svg_icons/Language'
import Caret from '../svg_icons/Caret'
import Camera from '../svg_icons/Camera'
import CaretDown from '../svg_icons/CaretDown'
import { standardInputStyling } from '../forms/TextInput'
import CopyToClipboardButton from '../buttons/CopyToClipboardButton'
import gql from 'graphql-tag'
import { useQuery } from 'urql'
import LoadingSpinner from './LoadingSpinner'
import CrocodileRight from '../svg_icons/CrocodileRight'

export const CourseSpecificationValues = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	min-width: fit-content;
`
const StyledCourseIncludesRow = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-left: 0 !important;
`
export const CourseIncludesRow = ({ t, included }) =>
	included && (
		<>
			<SmallPageSectionDivider />
			<SmallPageSection style={{ marginBottom: theme.margins.small, marginTop: theme.margins.small }}>
				<P3>{t('common:workshop.thisIncludes')}:</P3>
				<StyledCourseIncludesRow>
					<P4>{included}</P4>
				</StyledCourseIncludesRow>
			</SmallPageSection>
		</>
	)

export const StyledProfilePicture = styled.div`
	border-radius: 50%;
	overflow: hidden;
	box-shadow: #00000017 1px 1px 9px 0px;
	${({ width }) => css`
		min-width: ${width}px;
		max-width: ${width}px;
		height: ${width}px;
	`}
`

const StyledInitialsOrAvatar = styled.div`
	color: ${theme.colors.lightTextColor};
	background-color: ${theme.colors.gray};
	text-align: center;
	text-transform: uppercase;
	overflow-wrap: normal;
	display: flex;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: center;
	${({ width }) => css`
		line-height: ${width}px;
	`}
`

export const ProfilePicture = props => {
	const { profileName, imagePath, width = 48 } = props
	let profilePictureExists = true
	let content = null
	if (!imagePath) {
		profilePictureExists = false
		if (!profileName) {
			content = <User />
		} else {
			// Trim beginning and end of profileName of whitespace(s),
			// plus change all multiple spaces to single space
			const fullName = profileName.trim(profileName).replace(/\s{2,}/g, ' ')
			const splitNames = fullName.split(' ')
			const initials = splitNames.map(name => name.substring(0, 1).toUpperCase())
			content = initials.join('').substr(0, 2)
		}
	}
	return (
		<StyledProfilePicture width={width}>
			{profilePictureExists ? (
				<NonResponsiveImage imagePath={imagePath} width={width} type="PROFILE" alt={profileName} shadow />
			) : (
				<StyledInitialsOrAvatar width={width}>{content}</StyledInitialsOrAvatar>
			)}
		</StyledProfilePicture>
	)
}

export const CourseExportRow = ({ hostPath, hostName, hostImage, t }) => (
	<SmallPageSectionRepulsiveRow>
		<div>
			<P3>{t('copy:checkoutPage.hostTitle')}:</P3>
			<div>{hostName}</div>
		</div>
		<CourseSpecificationValues>
			{hostPath ? (
				<a href={hostPath} target="_blank">
					<ProfilePicture profileName={hostName} imagePath={hostImage} />
				</a>
			) : (
				<ProfilePicture profileName={hostName} imagePath={hostImage} />
			)}
		</CourseSpecificationValues>
	</SmallPageSectionRepulsiveRow>
)
export const StyledScarcityBadge = styled.div`
	background: ${theme.colors.fade(theme.colors.black, 0.9)};
	border-radius: 100px;
	display: flex;
	align-items: center;
	opacity: ${({ show }) => (show ? 1 : 0)};
	transition: opacity 2s;
	> svg {
		width: 14px;
		height: 14px;
		margin-right: ${theme.margins.XXSmall};
	}
	> p,
	> svg {
		color: white;
	}
	padding: ${theme.paddings.XSmall} ${theme.paddings.small};
	> p {
		${limitRows(1)};
	}
`
export const ScarcityBadge = ({ scarcity, show = true }) => {
	return (
		<StyledScarcityBadge show={show}>
			{scarcity.icon === 'FEW_SPOTS_LEFT' && <Fire />}
			{scarcity.icon === 'RECENTLY_BOOKED' && <Lightning />}
			{scarcity.icon === 'RARE_FIND' && <Diamond />}
			{scarcity.icon === 'TEAM_BUILDING' && <Group />}
			{scarcity.icon === 'POPULAR_GIFT' && <Gift />}
			<P5>{scarcity.text}</P5>
		</StyledScarcityBadge>
	)
}

export const IncludesItem = styled.div`
	display: flex;
	align-items: center;
	svg {
		margin-right: ${theme.margins.XSmall};
	}
	a {
		// Use this so the address link doesn't go outside the screen on long address names. This will break up the word (e.g. address name) if overflown
		overflow-wrap: anywhere;
	}
`

export const CourseDetailsRow = ({
	t,
	numberOfDates,
	duration,
	language,
	minParticipants,
	maxParticipants,
	physical,
	googleMapsUrl,
	addressWithoutCountry,
}) => {
	return (
		<ContentRow childrenPerRow={2} margins={{ innerVertical: theme.margins.largeInt, top: theme.margins.largeInt }}>
			<IncludesItem>
				<Clock />
				<P4>
					{numberOfDates > 1 ? (
						<>
							{numberOfDates} {t('common:workshop.session_plural')}
						</>
					) : (
						duration
					)}
				</P4>
			</IncludesItem>
			<IncludesItem>
				<Language />
				<P4>
					{t('copy:coursePage.hostedIn')} {language}
				</P4>
			</IncludesItem>
			<IncludesItem>
				<Group />
				<P4>
					{minParticipants === maxParticipants
						? minParticipants
						: `${minParticipants} - ${maxParticipants} ${t('copy:coursePage.participants')}`}
				</P4>
			</IncludesItem>
			<IncludesItem>
				{physical ? (
					<>
						<a href={googleMapsUrl} target="_blank" rel="noopener">
							<Caret />
						</a>
						<UnderlinedA href={googleMapsUrl} target="_blank" rel="noopener">
							{addressWithoutCountry}
						</UnderlinedA>
					</>
				) : (
					<>
						<Camera />
						<P4>Online</P4>
					</>
				)}
			</IncludesItem>
		</ContentRow>
	)
}

const ShowAllDatesWrapper = styled(P4)`
	display: flex;
	align-items: center;
	margin-top: ${({ open }) => (open ? theme.margins.small : theme.margins.XXSmall)};
	margin-bottom: ${({ showNextDate }) => (showNextDate ? theme.margins.XSmall : theme.margins.XXSmall)};
	cursor: pointer;
	> svg {
		transition: transform 0.3s;
		transform: rotateZ(${({ open }) => (open ? 180 : 0)}deg);
	}
`
const DateWrapper = styled.div`
	* {
		color: ${({ hasEnded }) => (hasEnded ? theme.colors.lightTextColor : theme.colors.textColor)};
	}
	span {
		color: ${theme.colors.lightTextColor};
	}
`
export const DateAndTimeInfo = ({ courseDate }) => {
	const { t } = useTranslation()
	const [toggleDate, setToggleDate] = useState(false)
	const numberOfDates = courseDate.consecutiveCourseDates.length + 1
	const showNextDate = courseDate.hasEnded && courseDate.nextDate && courseDate.nextDate !== courseDate.date
	return (
		<div>
			<DateWrapper hasEnded={courseDate.hasEnded && courseDate.nextDate !== courseDate.date}>
				<p>
					{numberOfDates > 1 && <strong>Start: </strong>}
					{courseDate.date}
					{courseDate.startsAtYear && ', ' + courseDate.startsAtYear}
					<br />
					{courseDate.timeFrame}&nbsp;<span>CEST</span>
				</p>
			</DateWrapper>
			{numberOfDates > 1 && (
				<>
					{toggleDate &&
						courseDate.consecutiveCourseDates.map((consecutiveCourseDate, i) => (
							<DateWrapper
								hasEnded={consecutiveCourseDate.hasEnded}
								style={{
									marginTop: theme.margins.small,
								}}
								key={i}
							>
								<div>{consecutiveCourseDate.date}</div>
								<div>
									{consecutiveCourseDate.timeFrame}&nbsp;<span>CEST</span>
								</div>
							</DateWrapper>
						))}
					<ShowAllDatesWrapper
						open={toggleDate}
						showNextDate={showNextDate}
						textColor="lightTextColor"
						onClick={e => {
							e.preventDefault()
							e.stopPropagation()
							setToggleDate(!toggleDate)
						}}
					>
						{t('common:workshop.showAllCourseSessions', { val: numberOfDates })}
						<CaretDown />
					</ShowAllDatesWrapper>
					{showNextDate && (
						<>
							<p>
								<strong>{t('common:workshop.next')}: </strong>
								{courseDate.nextDate}
								<br />
								{courseDate.nextTimeFrame}
							</p>
							<br />
						</>
					)}
				</>
			)}
		</div>
	)
}

const getCourseSharingLinks = gql`
	query ($id: ID!) {
		course(id: $id) {
			url
			hostNewBookingUrl
		}
	}
`
export const ShareLinkRow = styled(SmallPageSectionRepulsiveRow)`
	> * {
		margin-bottom: 0;
		margin-top: 0;
		:last-child {
			min-width: fit-content;
		}
	}
	input {
		cursor: pointer;
		${standardInputStyling()}
	}
`
// Display copyable sharing links for a course. Send in courseId to fetch them, or send them in directly if you already have them
export const CourseSharingLinks = ({ courseId, url, hostNewBookingUrl }) => {
	const { t, i18n } = useTranslation()
	const [result] = useQuery({
		query: getCourseSharingLinks,
		variables: { id: courseId },
		pause: !courseId,
	})
	const { data, fetching, error } = result
	if (courseId && (fetching || error)) return <LoadingSpinner />
	if (data?.course) {
		url = data.course.url
		hostNewBookingUrl = data.course.hostNewBookingUrl
	}
	return (
		<>
			<P2>{t('admin:coursesPage.coursePage')}</P2>
			<ShareLinkRow>
				<CopyToClipboardButton textToCopy={url} wide>
					<input readOnly value={url} />
				</CopyToClipboardButton>
				<a href={url}>
					<CallToAction>
						{t('common:buttons.viewPage')}
						<CrocodileRight />
					</CallToAction>
				</a>
			</ShareLinkRow>
			<P2>{t('admin:coursesPage.paymentLink')}</P2>
			<ShareLinkRow>
				<CopyToClipboardButton textToCopy={hostNewBookingUrl} wide>
					<input readOnly value={hostNewBookingUrl} />
				</CopyToClipboardButton>
				<a href={hostNewBookingUrl}>
					<CallToAction>
						{t('common:buttons.viewPage')}
						<CrocodileRight />
					</CallToAction>
				</a>
			</ShareLinkRow>
			<p>
				<a
					href={
						i18n.language === 'sv'
							? 'https://organizerhelp.ejbla.com/article/176-vad-ar-delningslankar'
							: 'https://organizerhelp.ejbla.com/article/242-sharing-links'
					}
					target="_blank"
				>
					{t('admin:coursesPage.helpArticle')}
				</a>
			</p>
		</>
	)
}
