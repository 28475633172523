import React, { useContext } from 'react'
import { theme } from '../../../utils/theme'
import { P5, TextSpan } from '../../reusable_components/Typography'
import FilterContext from '../../../contexts/filter/FilterContext'
import styled from 'styled-components'
import Button from '../../reusable_components/buttons/Button'
import Breakpoint from '../../reusable_components/responsive/Breakpoint'
import { PageSidePaddingWrapper } from '../../reusable_components/layout/PageComponents'
import { SubCategoryLinksMobileLoader } from '../../categories/show/SubCategoryLinks'

export const TagsWrapperMobile = styled.div`
	opacity: ${({ loaded }) => (loaded ? 1 : 0)};
	transition: opacity 1s;
	height: ${({ tagHeight = 37 }) =>
		tagHeight}px; // Use a constant height (the height of a button) because the slider on mobile gets a weird height when first rendering (the sum of heights of all children stacked on each other)
	overflow: hidden;
	.slick-list {
		${theme.breakpoints.tablet.upToAndIncluding} {
			padding-left: ${theme.paddings.small};
		}
	}
	.slick-slide {
		padding-right: ${theme.paddings.XXSmall};
	}
`
export const TagsWrapperDesktop = styled(PageSidePaddingWrapper)`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: ${theme.margins.XXSmall};
`

const SkillTags = ({ t }) => {
	const { filterState, dispatch } = useContext(FilterContext)
	const skillTagsData = filterState.skillTags
	const settings = {
		dots: false,
		infinite: false,
		arrows: false,
		swipeToSlide: true,
		variableWidth: true,
	}
	if (skillTagsData.length === 0) return null
	let numberOfShownTags = 3
	if (skillTagsData.length - 1 === numberOfShownTags) numberOfShownTags++ // Since we add a button if there are more, check if there is only one more, then we might as well show it instead of the button
	let showSeeMoreButton = skillTagsData.length > numberOfShownTags
	const skillTags = skillTagsData.slice(0, numberOfShownTags).map(({ checked, name, listedCoursesCount }, tagIndex) => {
		const skillTagDispatch = () => {
			if (!listedCoursesCount) return
			skillTagsData[tagIndex].checked = !checked
			dispatch({
				type: 'SKILL_TAG',
				data: skillTagsData,
			})
		}
		return (
			<Button
				key={tagIndex}
				color={checked ? 'GOLD' : 'WHITE'}
				buttonType="ROUND"
				onClick={skillTagDispatch}
				disable={!listedCoursesCount}
			>
				<P5>
					{name}&nbsp;<TextSpan textColor={checked ? 'white' : 'lightTextColor'}>({listedCoursesCount})</TextSpan>
				</P5>
			</Button>
		)
	})
	const seeMoreButton = showSeeMoreButton && (
		<div>
			<Button
				color="OUTLINED_BLACK"
				buttonType="ROUND"
				onClick={() =>
					dispatch({
						type: 'OPEN_FILTER_OPTION',
						data: 'SKILL_TAG',
					})
				}
			>
				<P5>{t('common:buttons.seeMore')}</P5>
			</Button>
		</div>
	)
	return (
		<>
			<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>
				<SubCategoryLinksMobileLoader>
					{skillTags}
					{seeMoreButton}
				</SubCategoryLinksMobileLoader>
			</Breakpoint>
			<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
				<TagsWrapperDesktop>
					{skillTags}
					{seeMoreButton}
				</TagsWrapperDesktop>
			</Breakpoint>
		</>
	)
}

export default SkillTags
