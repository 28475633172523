import React from 'react'
import HalfTextHalfImage from './HalfTextHalfImage'
import { useTranslation } from 'react-i18next-new'
import CenteredText from './CenteredText'
import { DynamicPageSection, PageSidePaddingWrapper, XLargePageSectionDivider } from '../../layout/PageComponents'
import { theme } from '../../../../utils/theme'
import CourseCardsWrapper from '../../course_cards/CourseCardsWrapper'

const SaFrontendComponent = ({ frontendComponent, fetchedCourseIds, parentId, parentType, onFetch }) => {
	const { structure } = frontendComponent
	const { t, i18n } = useTranslation()
	return (
		<>
			{structure === 'half_text_half_image' && <HalfTextHalfImage frontendComponent={frontendComponent} t={t} />}
			{structure === 'centered_text' && <CenteredText frontendComponent={frontendComponent} t={t} />}
			{structure === 'divider' && (
				<PageSidePaddingWrapper>
					<XLargePageSectionDivider />
				</PageSidePaddingWrapper>
			)}
			{structure === 'collection' && (
				<DynamicPageSection margins={{ all: theme.margins.XLargeInt }}>
					<PageSidePaddingWrapper>
						<CourseCardsWrapper
							type="collections"
							id={frontendComponent.connectedComponent.id}
							parentId={parentId}
							parentType={parentType}
							lazyLoad
							quantity={4}
							exclude={fetchedCourseIds}
							paginateWithButton={frontendComponent.connectedComponent.paginate}
							paginate={frontendComponent.connectedComponent.paginate}
							onFetch={onFetch}
						/>
					</PageSidePaddingWrapper>
				</DynamicPageSection>
			)}
		</>
	)
}

export function replaceGoldTags(inputString) {
	if (!inputString) return null
	// Replace opening <gold> tags with working html tags for gold text
	let result = inputString.replace(/<gold>/g, `<span style="color: ${theme.colors.gold}">`)
	// Replace closing </gold> tags
	result = result.replace(/<\/gold>/g, '</span>')
	return result
}

export default SaFrontendComponent
