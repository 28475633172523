import React from 'react'
import styled, { css } from 'styled-components'
import { disableBodyScroll, enableBodyScroll } from '../../../utils/disableBodyScroll'
import PropTypes from 'prop-types'
import Close from '../svg_icons/Close'
import { PageSidePaddingWrapper } from '../layout/PageComponents'
import { theme } from '../../../utils/theme'
import { isMobileModalOpen, isSideBarOpen } from '../../../utils/applicationHelper'
import { createPortal } from 'react-dom'

const CloseButton = styled.div`
	cursor: pointer;
	position: absolute;
	background: white;
	border-radius: 100%;
	box-shadow: #d3d3d38a 1px 1px 0px 0px;
	top: ${theme.paddings.XSmall};
	right: ${theme.paddings.XSmall};
	display: flex;
`
const TopGrayLine = styled.div`
	position: absolute;
	border-radius: 5px;
	border-top: 5px solid ${theme.colors.fade(theme.colors.darkGray, 0.5)};
	width: 40px !important;
	top: 20px;
	left: 50%;
	transform: translate(-50%, -50%);
`
const Modal = styled.div`
	${({ displayModal, bottomOffset, hideCloseButton }) => css`
		${hideCloseButton
			? css`
					height: ${bottomOffset ? `calc(100% - ${bottomOffset})` : '100%'};
				`
			: css`
					border-top-right-radius: 20px;
					border-top-left-radius: 20px;
					box-shadow: #d3d3d38a 0 0 7px 0;
					height: ${bottomOffset ? `calc(97% - ${bottomOffset})` : '97%'};
				`}
		-webkit-tap-highlight-color: transparent;
		user-select: none;
		position: fixed;
		width: 100%;
		> *:not(:first-child) {
			width: 100%;
		}
		overflow-y: ${displayModal ? 'scroll' : 'hidden'};
		background: white;
		${bottomOffset
			? css`
					bottom: ${displayModal ? bottomOffset : `calc(${bottomOffset} - 110%)`};
				`
			: css`
					bottom: ${displayModal ? '0' : '-110%'};
				`}
		left: 0;
		z-index: ${({ zIndex }) => zIndex};
		transition: bottom 0.2s;
	`}
`
const DefaultWrapper = styled(PageSidePaddingWrapper)`
	padding-top: ${theme.paddings.large};
	padding-bottom: ${theme.paddings.small};
`
let startedSwipeAtTop = false
export default class MobileModal extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {}
		this.modalRef = React.createRef()
		this.swipeListener = this.swipeListener.bind(this)
		this.touchStartListener = this.touchStartListener.bind(this)
	}

	touchStartListener(event) {
		startedSwipeAtTop = this.modalRef.current.scrollTop === 0
	}

	// Add a swipe listener to close the modal when the user swipes down on mobile, if the swipe started when the modal was scrolled to the top
	swipeListener(event) {
		if (
			startedSwipeAtTop &&
			this.modalRef.current.scrollTop === 0 &&
			(this.modalRef.current.contains(event.target) || this.modalRef.current === event.target)
		)
			this.props.onClose()
	}

	componentDidMount() {
		// Disable scroll on mount when there is an adminDestination, because then it is opened during SSR and on mount
		if (this.props.displayModal) disableBodyScroll(this.modalRef.current)
		this.setState({ mounted: true })
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.displayModal) {
			// Disable scroll on body behind the the scrollable modal to eliminate bug on mobile
			// where the body can be scrollable through the modal
			disableBodyScroll(this.modalRef.current)
			document.addEventListener(`swiped-down`, this.swipeListener)
			document.addEventListener('touchstart', this.touchStartListener)
		} else {
			// Enable back body scroll if this modal is the only currently open modal when it is being closed
			if (!isMobileModalOpen() && !isSideBarOpen()) enableBodyScroll(this.modalRef.current)
			document.removeEventListener(`swiped-down`, this.swipeListener)
			document.removeEventListener(`touchstart`, this.touchStartListener)
		}
	}
	componentWillUnmount() {
		// Enable back body scroll if this modal is the only currently open modal when it is being closed
		if (!isMobileModalOpen() && !isSideBarOpen()) enableBodyScroll(this.modalRef.current)
		document.removeEventListener(`swiped-down`, this.swipeListener)
		document.removeEventListener(`touchstart`, this.touchStartListener)
	}

	render() {
		const {
			displayModal,
			onClose,
			bottomOffset,
			children,
			hideCloseButton,
			zIndex = 8998,
			useDefaultWrapper = true,
		} = this.props
		// We use createPortal to render the modal directly under body, so the modal isn't affected by whatever div it is rendered in. Only use createPortal after it is mounted, since createPortal doesn't work during SSR
		return (
			<>
				{this.state.mounted &&
					createPortal(
						<Modal
							ref={this.modalRef}
							displayModal={displayModal}
							bottomOffset={bottomOffset}
							hideCloseButton={hideCloseButton}
							data-mobile-modal-open={displayModal}
							zIndex={zIndex}
							data-swipe-timeout="250"
							data-swipe-threshold="75"
						>
							{hideCloseButton || (
								<>
									<CloseButton>
										<Close width={22} height={22} onClick={onClose} />
									</CloseButton>
									<TopGrayLine />
								</>
							)}
							{useDefaultWrapper ? <DefaultWrapper>{children}</DefaultWrapper> : children}
						</Modal>,
						document.body,
					)}
			</>
		)
	}
}

MobileModal.propTypes = {
	displayModal: PropTypes.bool, // Should the modal be displayed?
	bottomOffset: PropTypes.string, // If the modal shouldn't come from the bottom of the screen, e.g. if there is a fixed footer at the bottom of the screen like on purchase page, the modal can be configured to start above it.
}
